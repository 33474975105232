module.exports = {
    enterNumber: {
        secondTitle: "ยินดีต้อนรับ",
        tips: "กรุณาใส่เบอร์โทรศัพท์มือถือของท่านเพื่อรับรหัสยืนยันแบบใช้ครั้งเดียว",
        phonePlaceHolder: "หมายเลขโทรศัพท์ของท่าน",
        phoneRuleMessage: "กรุณาใส่หมายเลขโทรศัพท์ที่ถูกต้อง",
        smallTips: "เบอร์โทรศัพท์ของท่านจะถูกเก็บรักษาเป็นความลับ",
        getOTP: "กดรับ OTP",
        toastSuccess: "สำเร็จ",
        toastFail: "ไม่สำเร็จ",
        readPrivacyPolicy: "ข้าพเจ้าได้อ่านและยอมรับนโยบายคุ้มครองความเป็นส่วนตัวของแบรนด์ {0} (ศึกษานโยบายความเป็นส่วนตัวได้ที่ {1})",
        readPrivacyPolicyForTFAndLL: "ข้าพเจ้าได้อ่านและยอมรับนโยบายคุ้มครองความเป็นส่วนตัวของแบรนด์ {0} {1}",
        privacyPolicy: "ศึกษานโยบายความเป็นส่วนตัวได้ที่",
    },
    enterOTP: {
        secondTitle: "กรอกเลข OTP (อ้างอิง :{0})",
        tips: "กรุณากรอกเลขรหัส OTP 6 หลักที่ได้รับจาก SMS บนหมายเลขที่ท่านลงทะเบียน",
        resendOTP: "ขอรับเลข OTP อีกครั้ง :",
        invalidOTP: "รหัส OTP ไม่ถูกต้อง",
        OTPRuleMessage: "ลูกค้ากดรหัส OTP ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
        verify: "ยืนยันเพื่อตรวจสอบ",
        ok: "ตกลง",
    },
    registration: {
        malay: 'Malay',
        chinese: 'Chinese',
        indian: 'Indian',
        other: 'Other',
        counter: 'สาขา',
        counterth: 'สาขา',
        salutation: 'คำนำหน้า',
        secondTitle: "Multiple Membership Found!",
        noUserSecondTitle: "One More Step",
        tips: "Please complete the form below to confirm your membership details and ensure your loyalty points are awarded for your transactions.",
        formValueTitle: "Title",
        formValueFirstName: "ชื่อจริง",
        formValueLastName: "นามสกุล",
        formValueDateOfBirth: "Date of Birth",
        formValueDay: "วันที่เกิด",
        formValueMonth: "เดือนเกิด",
        formValueYear: "ปีเกิด",
        formValueMobileNumberth: "เบอร์โทรติดต่อ",
        formValueMobileEmailth: "อีเมลล์",
        formValueMobileGender: "Gender",
        formValueMobileGenderFemale: "Female",
        formValueMobileGenderMale: "Male",
        secondTips1: "I would like receive news, offers and other marketing communications form, and be contacted for surveys by ",
        secondTips2: " and their products as listed in the Pricacy Notice overleaf via:",
        subscribeAll: "All of the following channels",
        marketiong: "Or the marketing channels selected individually",
        marketiongEmail: "Emails",
        marketiongText: "Text Messages (SMS/WhatsApp)",
        marketiongPhone: "Phone Calls",
        confirm1: "By signing below, I confirm I am 18 years old or above, I wish to register my information with ",
        brandName: "JO MALONE LONDON",
        confirm2: ", that i have read , understood and agreed to be bound by the relevant terms and conditions and the Privacy Notice as set out overleaf regarding how my personal data may be used.",
        smallTips1: "I have read and agree to the ",
        smallTips2: "Privacy Policy ",
        smallTips3: " and ",
        smallTips5: "the membership program's ",
        submit: "ยืนยันและส่งข้อมูล",
        continue: "Continue with registration",
        oK: "OK",
        cancle: "Cancel",
        confirm: 'ยืนยัน',
        statementUnderGender1: "ข้าพเจ้าได้อ่านและยอมรับนโยบายคุ้มครองความเป็นส่วนตัวของแบรนด์ ",
        possessive: "",
        possessive1: "ตลอดถึงข้อกำหนดและเงื่อนไขของรายการสะสมรางวัล",
        privacyPolicy: " ศึกษานโยบายความเป็นส่วนตัวได้ที่",
        period: ".", 
        period1: "", 
        statementSubscribe1: "I agree to receive news, offers and other marketing communications from, and be contacted for surveys by, ",
        statementSubscribe2:"Cosmetics Pte. Ltd. (operating entity of ",
        statementSubscribe3: "about any brands owned by its parent company, the Estée Lauder Companies Inc., including any brands acquired or launched in the future, and their products of the same kinds as listed in the ",
        statementSubscribe4:"via:",
        statementButton1:"ข้าพเจ้าบรรลุนิติภาวะแล้ว และข้าพเจ้าได้อ่านและยอมรับข้อกำหนดและเงื่อนไขของแบรนด์ ",
        statementButton2:" and by ticking this box I confirm that I am of legal adult age or above and I have read, understood and agree to",
        loyaltyProgram: "",
        rulerFirstname: 'กรุณาใส่ชื่อจริง',
        rulerFirstname1: 'กรุณากรอกชื่อจริงเป็นภาษาไทย',
        rulerLastname: 'กรุณาใส่นามสกุล',
        rulerLastname1: 'กรุณากรอกนามสกุลเป็นภาษาไทย',
        rulerSalutation: 'กรุณาเลือกคำนำหน้า',
        rulerEmail: 'กรุณาใส่อีเมลล์ที่ติดต่อได้',
        rulerBirthday: 'กรุณาใส่วันเกิด.',
        rulerBirthmonth: 'กรุณาใส่เดือนเกิด.',
        alreadyRP: 'เลขหมายโทรศัพท์นี้ถูกใช้งานแล้ว กรุณาติดต่อเจ้าหน้าที่หน้าร้านเพื่อตรวจสอบข้อมูล.',
        alreadyRE: 'อีเมลล์นี้ถูกใช้งานแล้ว กรุณาติดต่อเจ้าหน้าที่หน้าร้านเพื่อตรวจสอบข้อมูล.',
        newSub1: "Yes! By completing and submitting my information with this registration form, I agree to receive news, offers and other marketing communications from, and be contacted for surveys by, Estee Lauder Malaysia Sdn. Bhd. (operating entity of ",
        newSub2: "), via emails, text messages, phone calls or postal mails, about any brands owned by our parent company, the Estée Lauder Companies Inc., including any brands acquired or launched in the future, and their products of the same kinds as listed in the ",
        ms: "Ms",
        mrs: "นาง",
        mr: "นาย",
        dr: "Dr",
        othe: "Other",
        khun: 'คุณ',
        miss: 'นางสาว',
        languageCode: 'Preferred Language',
        formsomcc: 'ข้าพเจ้าขอให้ความยินยอมและขอรับข่าวสาร ข้อเสนอและการติดต่อสื่อสารอื่นๆรวมถึงการติดต่อเพื่อทำแบบสำรวจ โดยบริษัทเอลก้า (ประเทศไทย) จำกัด อันเกี่ยวกับแบรนด์ภายใต้บริษัท Estee Lauder Companies Inc และผลิตภัณฑ์ประเภทเดียวกันกับที่ระบุไว้ในนโยบายคุ้มครองข้อมูลส่วนบุคคลผ่านช่องทางต่างๆ เช่น โทรศัพท์ ไปรษณีย์ ข้อความ อีเมล การแจ้งเตือน หรือข้อความอื่นๆหรือผ่านโฆษณาที่แสดงให้ข้าพเจ้าทราบถึงผลิตภัณฑ์ และ/หรือ บริการที่ถูกปรับให้เหมาะสมกับความกับความสนใจของข้าพเจ้าบนโซเชียลมีเดียและเว็บไซต์อื่นๆ ซึ่งรายชื่อแบรนด์เหล่านี้สามารถดูได้ที่ ',
        formsomcc2: 'Our Brands – The Estée Lauder Companies Inc. (elcompanies.com)',
        formValuebmama: 'รหัสพนักงานที่ให้บริการ',
        termsAndConditions: "ข้าพเจ้าบรรลุนิติภาวะแล้ว และข้าพเจ้าได้อ่านและยอมรับข้อกำหนดและเงื่อนไขของแบรนด์ {0} ตลอดถึงข้อกำหนดและเงื่อนไขของรายการสะสมรางวัล {1}",
        loyaltyTermsAndCondition: 'Loyalty Terms and Condition'
    },
    linkConsumer: {
        secondTitle: "Membership added",
        thank: "Thank you, your membership has been successfully added.",
        done: "Done"
    },
    index:{
        copyRight:"©La Mer Technology, Inc."
    },
    barcode: {
        infoTH: 'ยินดีต้อนรับสู่ {0} กรุณาแสดงบาร์โค้ดนี้ที่หน้าเคาน์เตอร์สาขา',
    }
}
